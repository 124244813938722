export const seniorityCalculator = () => {
  const calculators = document.querySelectorAll('.seniority-calculator');

  if (calculators) {
    calculators.forEach(calculator => {
      let counter = 0;
      const calcButton = calculator.querySelector('[data-calc]');
      const addButton =  calculator.querySelector('[data-add]');
      const printButton =  calculator.querySelector('[data-print]');
      const table = calculator.querySelector('[data-table]');
      const results = calculator.querySelector('[data-results]');
      const message = calculator.querySelector('[data-message]');

      const inputs = calculator.querySelectorAll('.seniority-calculator__input');

      const name = calculator.querySelector('[data-name]');
      const from = calculator.querySelector('[data-from]');
      const to = calculator.querySelector('[data-to]');

      let elementList = [];

      from.addEventListener('change', () =>
        compare(from, to, message, addButton)
      );

      to.addEventListener('change', () =>
        compare(from, to, message, addButton)
      );

      addButton.addEventListener('click', (e) => {
        e.preventDefault();

        if (emptyValidation(inputs)) {
          counter++;
          addElement(inputs, name, from, to, counter, elementList, table, results);
        }
      });

      calcButton.addEventListener('click', (e) => {
        e.preventDefault();

        if (elementList.length == 0) {
          if (emptyValidation(inputs)) {
            counter++;
            addElement(inputs, name, from, to, counter, elementList, table, results);
          }
        }

        calc(calculator, table, elementList);
      });

      printButton.addEventListener('click', (e) => {
        e.preventDefault();
        
        if (elementList.length) {
          let printContent = table.innerHTML;

          let popupWinWidth = 800; 
          let popupWinHeight = 500;
          let left = (screen.width - popupWinWidth) / 4;
          let top = (screen.height - popupWinHeight) / 4;
          
          let myWindow = window.open('', '', 'resizable=yes, width=' + popupWinWidth
          + ', height=' + popupWinHeight + ', top='
          + top + ', left=' + left);
          myWindow.document.write('<html><head><title></title>');
          myWindow.document.write('<link rel="stylesheet" href="'+ ff.assetsUri +'/styles/print.css" type="text/css"/>');
          myWindow.document.write('</head><body>');
          myWindow.document.write(printContent);
          myWindow.document.write('</body></html>');
          myWindow.document.close();
          myWindow.focus();

          setTimeout(function() {
            myWindow.print();
          }, 1000);

          return true;
        }
      });
    });
  }

  function calc(calculator, table, elementList) {
    let resultInfo = calculator.querySelector('.table__result');
    if (resultInfo) {
      resultInfo.remove();
    }

    if (elementList.length) {
      let sum = 0;
      elementList.forEach((element, index) => {
        sum = sum + element['diffInDays']
      });

      sum = new Date(0).getTime() + 86400000 * sum - 43200000;
      sum = new Date(sum);

      let result = new Date(sum);
      
      let fullWorkedTime = (result.getFullYear() - 1970) + " lat " + result.getMonth() + " miesięcy " + result.getDate() + " dni";
    
      let row = document.createElement('div');
      row.classList.add('table__result');
      row.innerHTML = '<h3 class="table__result-header">Przepracowany okres (suma): <strong>' + fullWorkedTime + '</strong></h3>';
      table.appendChild(row);  
    }
  }

  function addElement(inputs, name, from, to, counter, elementList, table, results) {
    if (elementList.length >= 20) {
      addButton.classList.add('-disabled');
    }

    if (! table.classList.contains('-active')) {
      table.classList.add('-active');
    }
    
    let diff = Math.abs(new Date(to.value).getTime() - new Date(from.value).getTime());
    
    let element = {
      id: 'el_'+counter,
      name: name.value, 
      from: new Date(from.value),
      to: new Date(to.value),
      diff: new Date(diff),
      diffInDays: getDifferenceInDays(new Date(from.value), new Date(to.value))
    };

    elementList.push(element);

    let workedTime = (element['diff'].getFullYear() - 1970) + " lat " + element['diff'].getMonth() + " miesięcy " + element['diff'].getDate() + " dni";

    let row = document.createElement('tr');
    row.classList.add('table__row');
    row.setAttribute('id', element['id']);
    row.innerHTML = '<td class="table__col">'+ counter +')</td><td class="table__col"><strong>'+ name.value +'</strong></td><td class="table__col">'+ dateConvert(element['from']) +'</td><td class="table__col">'+ dateConvert(element['to']) +'</td><td class="table__col">'+ workedTime +'</td><td class="table__col"><span class="table__row-remove" data-row-remove="">&#x2715</span></td>';
    results.appendChild(row);

    reset(inputs);

    let remove = row.querySelector('[data-row-remove]');
    remove.addEventListener('click', (e) => {
      let elementId = remove.parentNode.parentNode.id;
      
      elementList.forEach((element, index) => {
        if (element.id == elementId) {
          elementList.splice(index, 1);
        }
      });

      remove.parentNode.parentNode.remove();
    });
  }

  function compare(from, to, message, addButton) {
    let startValue = (new Date(from.value)).getTime();
    let endValue = (new Date(to.value)).getTime();

    if (endValue < startValue) {
      message.innerHTML = 'Data rozpoczęcia musi być wcześniejsza, niz data zakończenia.';
      addButton.disabled = true;
      addButton.classList.add('-error');
    } else {
      message.innerHTML = '';
      addButton.disabled = false;
      addButton.classList.remove('-error');
    }
  }

  function reset (fields) {
    fields.forEach((field) => {
      field.value = "";
    });
  }

  function emptyValidation(fields) {
    let validated = [];

    if (fields) {
      fields.forEach((field, index) => {
        let value = field.value;
        
        if (value == "" || value == null) {
          field.classList.add('-error');
          validated[index] = false;
        } else {
          field.classList.remove('-error');
          validated[index] = true;
        }
      });
    }

    if (validated.includes(false)) {
      return false;
    } 
      
    return true;
  }

  function dateConvert (date) {
    let fromDD = date.getDate();
    if (fromDD < 10) {
      fromDD='0'+fromDD;
    } 

    let fromMM =  date.getMonth();
    fromMM += 1;  
    if (fromMM < 10) {
      fromMM='0'+fromMM;
    } 

    let fromYYYY = date.getFullYear();

    let formDate = fromDD + '.'+fromMM + '.' + fromYYYY + 'r.';

    return formDate;
  }

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24) + 1;
  }
}

